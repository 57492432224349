import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XMap from '@dispatcher-stratus/ecks/dist/std/map'
import XOptional from '@dispatcher-stratus/ecks/dist/std/optional'
import XString from '@dispatcher-stratus/ecks/dist/std/string'

export class Form extends XRecord {
  private fields: Map<string, TypedValue>

  constructor(value: Map<string, TypedValue>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const fields = value.get('fields')
    if (fields === undefined || !(fields instanceof Map)) {
      throw new TypeError()
    }

    this.fields = fields
  }
  get(key: TypedValue): TypedValue {
    return (
      this.fields.get(key.__toString().replace(/(^'|'$)/g, '')) ??
      new XOptional(
        this.__state,
        this.__value.get(key.__toString().replace(/(^'|'$)/g, ''))
      )
    )
  }
  __new(value: Map<string, TypedValue>): XRecord {
    return new Form(value, this.__state)
  }
}
