import { TextField as T, TextFieldProps } from '@material-ui/core'
import React, { ChangeEvent, useRef, useState } from 'react'

export const TextField = (props: TextFieldProps) => {
  const inputEl = props.inputRef
    ? (props.inputRef as React.RefObject<HTMLInputElement>)
    : useRef<HTMLInputElement>(null)
  const [prevVal, setPrevVal] = useState('')

  return (
    <T
      {...props}
      inputRef={inputEl}
      onFocus={(e) => {
        setPrevVal(inputEl?.current?.value || '')
        if (props !== undefined && typeof props.onFocus === 'function')
          props?.onFocus?.(e)
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !props.multiline) inputEl?.current?.blur()
        if (
          event.key === 'Escape' ||
          event.key === 'Esc' ||
          event.keyCode === 27
        ) {
          const changeEvent = {
            target: {
              name: inputEl.current?.name,
              checked: inputEl.current?.checked,
              value: prevVal
            }
          } as ChangeEvent<HTMLInputElement>
          if (props !== undefined && typeof props.onChange === 'function')
            props.onChange?.(changeEvent)
          inputEl?.current?.blur()
        }
        if (props !== undefined && typeof props.onKeyDown === 'function')
          props.onKeyDown?.(event)
      }}
      onBlur={(event) => {
        if (props !== undefined && typeof props.onBlur === 'function')
          props.onBlur?.(event)
      }}
    />
  )
}
