import React, { FC, useEffect, useState } from 'react'

export const DisableContextAndShortcuts: FC = () => {
  const [isDebugMode, setIsDebugMode] = useState(false)
  const handleContextEvent = (e: Event) => {
    e.preventDefault()
  }
  const handleKeyPressEvent = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.shiftKey && e.key === 'Z') {
      setIsDebugMode((isDebugMode) => !isDebugMode)
    }
    if (
      (e.ctrlKey && ['r', 'F5', 'u', 'p', 'f', 'g'].includes(e.key)) ||
      (e.shiftKey && e.key === 'F5') ||
      ['F3', 'F5', 'F7', 'F11', 'F12'].includes(e.key) ||
      (e.ctrlKey &&
        e.shiftKey &&
        ['C', 'E', 'G', 'I', 'J', 'P', 'R', 'S', 'X'].includes(e.key))
    ) {
      e.preventDefault()
    }
  }
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return
    if (isDebugMode) {
      document.removeEventListener('contextmenu', handleContextEvent)
      document.removeEventListener('keydown', handleKeyPressEvent)
    } else {
      document.addEventListener('contextmenu', handleContextEvent)
      document.addEventListener('keydown', handleKeyPressEvent)
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextEvent)
      document.removeEventListener('keydown', handleKeyPressEvent)
    }
  }, [isDebugMode])
  return <div style={{ display: 'none' }} />
}

export default DisableContextAndShortcuts
