import {
  FormControlLabel,
  Switch,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core'
import { NodeContextType, NodeContext, NodeStateActions } from './NodeState'
import { EditableTextField } from './EditableTextField'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      '&:last-child': {
        'padding-bottom': 0
      }
    },
    label: {
      'margin-left': '8px'
    }
  })
)

export const EnableNameDescriptionFields = (props: { i18n?: any }) => {
  const { state, dispatch } = useContext<NodeContextType>(NodeContext)
  const { t } = useTranslation('translation', { i18n: props.i18n })

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        name: e.target.value.replace(/{\s*([^}]*(?:}(?!})[^}]*)*)}/g, '')
      }
    })
  }
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        description: e.target.value.replace(/{\s*([^}]*(?:}(?!})[^}]*)*)}/g, '')
      }
    })
  }
  const handleEnableChange = () => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        isEnabled: !state.node.isEnabled
      }
    })
  }

  const classes = useStyles()
  return (
    <div>
      <div>
        <FormControlLabel
          classes={classes}
          value='start'
          control={
            <Switch
              checked={state.node.isEnabled}
              onChange={handleEnableChange}
              color='secondary'
            />
          }
          label={state.node.isEnabled ? t('Enabled') : t('Disabled')}
          labelPlacement='start'
        />
      </div>
      <div>
        <EditableTextField
          className='text-lg font-bold'
          value={state.node.name}
          onChange={handleNameChange}
          placeholder={t('Name') || 'Name'}
          inputProps={{ maxLength: '100', autocomplete: 'new-password' }}
          style={{ minWidth: '150px' }}
        />
      </div>
      <div>
        <EditableTextField
          value={state.node.description}
          onChange={handleDescriptionChange}
          placeholder={t('Description') || 'Description'}
          inputProps={{ maxLength: '200' }}
          style={{ minWidth: '150px' }}
        />
      </div>
    </div>
  )
}
