import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XString from '@dispatcher-stratus/ecks/dist/std/string'
import XDate from '@dispatcher-stratus/ecks/dist/std/date'

export class Workflow extends XRecord {
  private id?: XString
  private name?: XString
  private modified?: XDate

  constructor (value: Map<string, TypedValue>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const id = value.get('id')
    if (id !== undefined && !(id instanceof XString)) {
      throw new TypeError()
    }
    const name = value.get('name')
    if (name !== undefined && !(name instanceof XString)) {
      throw new TypeError()
    }
    const modified = value.get('modified')
    if (modified !== undefined && !(modified instanceof XDate)) {
      throw new TypeError()
    }

    this.id = id
    this.name = name
    this.modified = modified
  }
  __new (value: Map<string, TypedValue>): XRecord {
    return new Workflow(value, this.__state)
  }
}
