import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XString from '@dispatcher-stratus/ecks/dist/std/string'

export class User extends XRecord {
  private dept?: XString
  private domain?: XString
  private email?: XString
  private first?: XString
  private full?: XString
  private home?: XString
  private last?: XString
  private name?: XString
  private profile?: XString
  private title?: XString
  private id?: XString

  constructor (value: Map<string, TypedValue>, state?: State) {
    const defaultState: State = { environment: new Map(), records: new Map() }
    const resolvedState = state || defaultState
    super(value, resolvedState)

    const dept = value.get('dept')
    if (dept !== undefined && !(dept instanceof XString)) {
      throw new TypeError()
    }
    const domain = value.get('domain')
    if (domain !== undefined && !(domain instanceof XString)) {
      throw new TypeError()
    }
    const email = value.get('email')
    if (email !== undefined && !(email instanceof XString)) {
      throw new TypeError()
    }
    const first = value.get('first')
    if (first !== undefined && !(first instanceof XString)) {
      throw new TypeError()
    }
    const full = value.get('full')
    if (full !== undefined && !(full instanceof XString)) {
      throw new TypeError()
    }
    const home = value.get('home')
    if (home !== undefined && !(home instanceof XString)) {
      throw new TypeError()
    }
    const last = value.get('last')
    if (last !== undefined && !(last instanceof XString)) {
      throw new TypeError()
    }
    const name = value.get('name')
    if (name !== undefined && !(name instanceof XString)) {
      throw new TypeError()
    }
    const profile = value.get('profile')
    if (profile !== undefined && !(profile instanceof XString)) {
      throw new TypeError()
    }
    const title = value.get('title')
    if (title !== undefined && !(title instanceof XString)) {
      throw new TypeError()
    }
    const id = value.get('id')
    if (id !== undefined && !(id instanceof XString)) {
      throw new TypeError()
    }

    this.dept = dept
    this.domain = domain
    this.email = email
    this.first = first
    this.full = full
    this.home = home
    this.last = last
    this.name = name
    this.profile = profile
    this.title = title
    this.id = id
  }
  __new (value: Map<string, TypedValue>): XRecord {
    return new User(value, this.__state)
  }
}
