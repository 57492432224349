import XRecord from '@dispatcher-stratus/ecks/dist/std/record'
import Ecks from '@dispatcher-stratus/ecks'
import { TypedValue } from '@dispatcher-stratus/ecks/dist/ast'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import XMap from '@dispatcher-stratus/ecks/dist/std/map'
import { objectToEcksMap } from './utils'

export const Base = class {
  constructor(data: any) {
    Object.assign(this, data)
  }
}

export const File = class extends XMap {
  // constructor (
  //   value: Record<string, any> | Map<string, TypedValue>,
  //   state?: State
  // ) {
  //   const defaultState: State = { environment: new Map(), records: new Map() };
  //   const resolvedState = state || defaultState;
  //   let resolvedValue = value as Map<string, TypedValue>;
  //   if (value instanceof Map) {
  //   } else {
  //     const typedValue = new Map(
  //       Object.entries(value).map(([k, v]) => [
  //         k,
  //         Ecks.fromJs(v, resolvedState),
  //       ])
  //     );
  //     resolvedValue = typedValue;
  //   }
  //   super(resolvedValue, resolvedState);
  // }
}

export const Tenant = class extends Base {}

export interface GetMetadataOptions {
  userId?: string
  workflowId?: string
  processId?: string
  fileId?: string
}