import { MetadataProcessError, findExpressions, objectToMap } from './utils'
import Ecks from '@dispatcher-stratus/ecks'
export * from './utils'
export * from './types'
import fetch from 'node-fetch'
import { GetMetadataOptions } from './types'
import { Process, User, Workflow, File } from './records'
import { State } from '@dispatcher-stratus/ecks/dist/types'
import { Form } from './records/Form'

export const expandString = (input: string, state: State) => {
  const matches = findExpressions(input)
  const ecksExpressions = matches.map((match) => {
    try {
      const evaludatedMatch = Ecks.eval(match.replace(/:\s*/, '.'), state)
      if (!evaludatedMatch)
        throw new Error('OCR Metadata Zone index has no data')
      return evaludatedMatch
    } catch (err: any) {
      return ''
      // throw new MetadataProcessError(match, err.message);
    }
  })
  const evaluatedExpressions = ecksExpressions.map((ecksExpr) => {
    if (ecksExpr) return Ecks.toJs(ecksExpr)
    return ''
  })
  let newStr = input
  evaluatedExpressions.forEach((val, i) => {
    newStr = newStr.replace(`{${matches[i]}}`, `${val}`)
    newStr = newStr.replace('[object Map]', 'undefined')
  })
  return newStr
}
export const getMetadata = async (options: GetMetadataOptions) => {
  const records = new Map<string, any>([
    ['user', User],
    ['workflow', Workflow],
    ['process', Process],
    ['file', File],
    ['form', Form],
  ])
  const state = { environment: new Map(), records }
  if (options.userId) {
    const resUser = await fetch(
      `https://${process.env.REGION ?? 'us-east-1'}.metadata-api.${
        process.env.DOMAIN ?? 'stratus.lol'
      }/api/v1/user/${options.userId}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          kind: 'record',
          name: 'user',
          value: {
            ...res?.data,
            name: res?.data?.email?.split('@')?.[0] || res?.data?.name,
          },
        }
      })
      .then((res) => Ecks.fromJs(res, state))
      .catch((err) => {
        return undefined
      })
    state.environment.set('user', resUser)
  }
  if (options.workflowId) {
    const resWorkflow = await fetch(
      `https://${process.env.REGION ?? 'us-east-1'}.metadata-api.${
        process.env.DOMAIN ?? 'stratus.lol'
      }/api/v1/workflow/${options.workflowId}/${options.workflowId}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          kind: 'record',
          name: 'workflow',
          value: {
            ...res?.data,
            modified: res?.data?.modified
              ? { kind: 'date', value: res?.data?.modified }
              : undefined,
          },
        }
      })
      .then((res) => Ecks.fromJs(res, state))
      .catch((err) => {
        console.log(err)
        return undefined
      })
    state.environment.set('workflow', resWorkflow)
  }
  if (options.processId && options.workflowId) {
    const resProcess = await fetch(
      `https://${process.env.REGION ?? 'us-east-1'}.metadata-api.${
        process.env.DOMAIN ?? 'stratus.lol'
      }/api/v1/process/${options.workflowId}/${options.processId}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          kind: 'record',
          name: 'process',
          value: {
            ...res?.data,
          },
        }
      })
      .then((res) => Ecks.fromJs(res, state))
      .catch((err) => {
        return undefined
      })
    state.environment.set('process', resProcess)
  }
  if (options.fileId && options.processId) {
    const resFile = await fetch(
      `https://${process.env.REGION ?? 'us-east-1'}.metadata-api.${
        process.env.DOMAIN ?? 'stratus.lol'
      }/api/v1/file/${options.processId}/${encodeURIComponent(options.fileId)}`
    )
      .then((res) => res.json())
      .then((res) => {
        const file = {
          kind: 'record',
          name: 'file',
          value: {
            ...res?.data,
          },
        }
        if (res?.data?.ocr) file.value.ocr = objectToMap(res?.data?.ocr)
        if (res?.data?.form)
          file.value.form = objectToMap(res?.data?.form)
        return file
      })
      .then((res) => {
        return {
          file: Ecks.fromJs(res, state),
          form: Ecks.fromJs(res.value.form, state),
        }
      })
      .catch((err) => {
        return undefined
      })
    if (resFile?.file) state.environment.set('file', resFile.file)
    if (resFile?.form) state.environment.set('form', resFile.form)
  }
  return state
}
