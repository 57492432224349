import React, { useEffect, useReducer, useState } from 'react'
import { Node } from './interfaces/Node'
import { Response } from '@dispatcher-stratus/stratusjs/types'
import * as StratusDesigner from '@dispatcher-stratus/stratusjs'
import { useLocation } from 'react-router-dom'
import { getNodeDefaults } from './NodeDefaults'
import {
  NodeState,
  NodeStateAction,
  NodeStateActions,
  nodeStateReducer
} from './NodeStateUpdate'

const nodeInitialState: NodeState = {
  node: getNodeDefaults(),
  hash: 0,
  wfid: '',
  nodeid: '',
  token: '',
  sessionid: '',
  configIsLoaded: false
}

export type NodeContextType<T = any> = {
  state: NodeState<T>
  dispatch: (action: NodeStateAction<T>) => void
  stratusDesigner: typeof StratusDesigner
  triggerValidation: object
  validationFunctions: Array<(...args: any[]) => any>
  addValidationFunction: (arg0: (...args: any[]) => any) => void
}
export const NodeContext = React.createContext<NodeContextType>({
  state: nodeInitialState,
  dispatch: () => {},
  stratusDesigner: StratusDesigner,
  triggerValidation: {},
  validationFunctions: [],
  addValidationFunction: () => {}
})

export const NodeContextProvider: React.FC<{
  configDefaults?: any
  children?: React.ReactNode
  windowOptions?: {
    title?: string
    size?: {
      width: number
      height: number
      minWidth?: number
      minHeight?: number
    }
  }
  skipHashOnConfigLoad?: boolean
}> = ({ configDefaults, children, windowOptions, skipHashOnConfigLoad }) => {
  const [state, dispatch] = useReducer(nodeStateReducer, {
    ...nodeInitialState,
    node: { ...nodeInitialState.node, config: { ...(configDefaults || {}) } }
  })
  const [validationFunctions, setValidationFunctions] = useState<
    Array<(...args: any[]) => any>
  >([])
  const contextValue = {
    state,
    dispatch,
    stratusDesigner: StratusDesigner,
    triggerValidation: {},
    validationFunctions,
    addValidationFunction: (arg0: (...args: any[]) => any) => {
      setValidationFunctions((validationFunctions) => [
        ...validationFunctions,
        arg0
      ])
    }
  }
  const { search } = useLocation()

  useEffect(() => {
    const wfid = search
      ? new URLSearchParams(search as any).get('wid') || ''
      : ''
    const nodeid = search
      ? new URLSearchParams(search as any).get('nid') || ''
      : ''
    const sessionid = search
      ? new URLSearchParams(search as any).get('sid') || ''
      : ''
    const token = search
      ? new URLSearchParams(search as any).get('token') || ''
      : ''
    const options = { wfid, nodeid, token, sessionid }
    dispatch({
      type: NodeStateActions.SET_NODEID_WFID_TOKEN,
      payload: { ...options }
    })
    StratusDesigner.setWindowTitle({
      ...options,
      data: windowOptions?.title || 'Example Title'
    })

    StratusDesigner.setWindowSize({
      ...options,
      data: windowOptions?.size || {
        width: 1100,
        height: 850,
        minWidth: 640,
        minHeight: 480
      }
    })

    const load = async () => {
      const nodeRes: Response<Node> = await StratusDesigner.getNodeConfig(
        options
      ).catch(() => {
        if (!skipHashOnConfigLoad)
          dispatch({
            type: NodeStateActions.UPDATE_HASH,
            payload: {}
          })
        return {} as any
      })

      if (nodeRes?.status === 204 || nodeRes?.status === 200) {
        dispatch({
          type: skipHashOnConfigLoad
            ? NodeStateActions.SET_NODE_CONFIG_NO_HASH
            : NodeStateActions.SET_NODE,
          payload: nodeRes?.data as Partial<Node>
        })
      } else {
        console.log('getNodeConfig failed', { nodeRes })
      }
      dispatch({
        type: NodeStateActions.CONFIG_IS_LOADED
      })
    }

    const loadSession = async () => {
      const url = `/api/node/sessions/${wfid}/${sessionid}`
      // TODO error catching
      const nodeRes = await fetch(url)
        .then((res) => {
          if (res.status < 300) return res
          throw res
        })
        .then((res) => res.json())
        .catch(() => {
          if (!skipHashOnConfigLoad)
            dispatch({
              type: NodeStateActions.UPDATE_HASH,
              payload: {}
            })
          return {} as any
        })
      if (nodeRes?.[nodeid]) {
        dispatch({
          type: skipHashOnConfigLoad
            ? NodeStateActions.SET_NODE_CONFIG_NO_HASH
            : NodeStateActions.SET_NODE,
          payload: nodeRes?.[nodeid] as Partial<Node>
        })
      } else {
        console.log('getNodeConfig session failed', { nodeRes })
        throw new Error('failed to load session from db')
      }
    }

    const loadWfxConfig = async () => {
      const getEnv = (env = 'stratus.lol', region = 'us-east-1'): string => {
        const URLS = {
          'stratus.lol': {
            'us-east-1': 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev',
            'eu-central-1': 'https://io59oayis1.execute-api.eu-central-1.amazonaws.com/dev',
            'ap-northeast-1': 'https://kt9ula9e5b.execute-api.ap-northeast-1.amazonaws.com/dev'
          },
          'dodgestrat.us': {
            'us-east-1': 'https://t09d4tj8oc.execute-api.us-east-1.amazonaws.com/beta',
            'eu-central-1': 'https://b6bkedjs92.execute-api.eu-central-1.amazonaws.com/beta',
            'ap-northeast-1': 'https://43jzx69z83.execute-api.ap-northeast-1.amazonaws.com/beta'
          },
          'scantripcloud.com': {
            'us-east-1': 'https://m41s6emkk9.execute-api.us-east-1.amazonaws.com/prod',
            'eu-central-1': 'https://mm67obwrd6.execute-api.eu-central-1.amazonaws.com/prod',
            'ap-northeast-1': 'https://nfp50jzorj.execute-api.ap-northeast-1.amazonaws.com/prod'
          }
        }
        return URLS?.[env]?.[region] || URLS['stratus.lol']['us-east-1']
      }
      const wfxDomain = getEnv(
        process.env.REACT_APP_DOMAIN,
        process.env.REACT_APP_REGION
      )
      const url = `${wfxDomain}/workflow/node/download?workflowId=${wfid}&nodeId=${nodeid}&arn=${sessionid}`
      // const url = `/api/node/wfx/${wfid ?? ''}/${nodeid ?? ''}`
      // TODO error catching
      const nodeRes = await fetch(url)
        .then((res) => {
          if (res.status < 300) return res
          throw res
        })
        .then((res) => res.json())
        .catch(() => {
          if (!skipHashOnConfigLoad)
            dispatch({
              type: NodeStateActions.UPDATE_HASH,
              payload: {}
            })
          return {} as any
        })
      if (nodeRes) {
        dispatch({
          type: skipHashOnConfigLoad
            ? NodeStateActions.SET_NODE_CONFIG_NO_HASH
            : NodeStateActions.SET_NODE,
          payload: nodeRes as Node
        })
        console.log('getNodeConfig from wfx succeeded', { nodeRes })
      } else {
        console.log('getNodeConfig from wfx failed', { nodeRes })
        throw new Error('failed to load config from wfx')
      }
    }

    if (sessionid) {
      loadSession()
        .catch(() => {
          return loadWfxConfig()
        })
        .catch((err) => console.log(err))
        .finally(() => {
          dispatch({
            type: NodeStateActions.CONFIG_IS_LOADED
          })
        })
    } else {
      load()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NodeContext.Provider value={contextValue}>{children}</NodeContext.Provider>
  )
}

export default NodeContextProvider
